import Provider from './provider';
import Service from './service';

/**
 * Parses a SERVICE element.
 *
 * @param service The element to parse.
 */
function parseService(service: Element): Service {
  const key = service.getAttribute('key');
  if (!key) throw new Error('Service without key');

  const name = service.getAttribute('name');
  if (!name) throw new Error('Service without name');

  const snippets = [];
  let description = null;

  for (let i = 0; i < service.children.length; i++) {
    const child = service.children[i];
    if (child.tagName === 'description') {
      if (!child.textContent) throw new Error('Empty description');
      description = child.textContent;
    } else if (child.tagName === 'snippet') {
      if (!child.textContent) throw new Error('Empty snippet');
      const domposition = child.getAttribute('domposition') || 'head::after';
      snippets.push({ value: child.textContent, domposition });
    } else {
      throw new Error('Invalid element: ' + child.tagName);
    }
  }

  if (!description) throw new Error('Empty description');

  return {
    key,
    name,
    description,
    snippets,
  };
}

/**
 * Parses a SERVICEGROUP element into an array of Services.
 *
 * @param serviceGroup The element to parse.
 */
function parseServiceGroup(serviceGroup: Element): Service[] {
  const services = [];

  for (let i = 0; i < serviceGroup.children.length; i++) {
    const child = serviceGroup.children[i];
    if (child.tagName === 'service') services.push(parseService(child));
    else throw new Error('Invalid element: ' + child.tagName);
  }

  return services;
}

/**
 * Parses a PROVIDER element.
 *
 * @param provider The element to parse.
 */
function parseProvider(provider: Element): Provider {
  const key = provider.getAttribute('key');
  if (!key) throw new Error('Provider without key');

  const name = provider.getAttribute('name');
  if (!name) throw new Error('Provider without name');

  const services = [];
  let description = null;

  for (let i = 0; i < provider.children.length; i++) {
    const child = provider.children[i];

    if (child.tagName === 'description') {
      if (!child.textContent) throw new Error('Empty description');
      if (description) throw new Error('Duplicate description');

      description = child.textContent;
    } else if (child.tagName === 'servicegroup') {
      services.push(...parseServiceGroup(child));
    }
  }

  if (!description) throw new Error('Missing description');

  return {
    key,
    name,
    description,
    services,
  };
}

/**
 * Parses the cookies root element.
 *
 * @param cookies The root element.
 */
function parseCookies(cookies: Element): Provider[] {
  const providers = [];

  for (let i = 0; i < cookies.children.length; i++) {
    const child = cookies.children[i];
    if (child.tagName === 'provider') providers.push(parseProvider(child));
    else throw new Error('Invalid element: ' + child.tagName);
  }

  return providers;
}

/**
 * Parses a cookie descriptor XML document.
 *
 * @param doc The XML document object.
 */
function parseDocument(doc: Document): Provider[] {
  const cookies = doc.firstElementChild;
  if (!cookies) throw new Error('Missing root element');

  return parseCookies(cookies);
}

/**
 * Callback function type for getSiteConfig.
 */
type SiteConfigCallback = (providers: Provider[]) => void;

/**
 * Returns the cookie configuration for the currennt site.
 *
 * @param callback Callback that receives the configuration.
 */
export function getSiteConfig(callback: SiteConfigCallback): void {
  const cookieFilename =
    document.currentScript?.dataset.cookies || 'cookies.xml';
  const xhr = new XMLHttpRequest();
  xhr.onload = (): void => {
    if (!xhr.responseXML) throw new Error('Could not parse cookie descriptor');
    callback(parseDocument(xhr.responseXML));
  };
  xhr.open('GET', '/' + cookieFilename);
  xhr.send();
}
