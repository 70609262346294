import { getSiteConfig } from './site/site-config';
import { initializeBanner } from './preferences/banner';
import { getAllowedModules } from './preferences/storage';
import Provider from './site/provider';

function buildSnippet(content: string): DocumentFragment {
  return document.createRange().createContextualFragment(content);
}

function loadAllSnippets(providers: Provider[]): void {
  for (const p of providers) {
    for (const s of p.services) {
      for (const sn of s.snippets) {
        const snippet = buildSnippet(sn.value);
        switch (sn.domposition) {
          case 'body::before':
            document.body.insertBefore(
              snippet,
              document.body.firstElementChild,
            );
            break;
          case 'body::after':
            document.body.appendChild(snippet);
            break;
          default:
            document.head.appendChild(snippet);
            break;
        }
      }
    }
  }
}

/**
 * The module's main function.
 */
export function main(): void {
  getSiteConfig(providers => {
    if (getAllowedModules() === '*') loadAllSnippets(providers);
  });

  document.addEventListener('DOMContentLoaded', () => initializeBanner());
}
