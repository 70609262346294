type AllowedModules = string[] | '*';
export function getAllowedModules(): AllowedModules {
  const allowedModules = localStorage.getItem('keksdose-allowed');
  if (!allowedModules) return [];

  if (allowedModules === '*') return '*';

  return allowedModules.split(',');
}

export function setAllowedModules(allowedModules: AllowedModules): void {
  if (Array.isArray(allowedModules) && !allowedModules.length)
    localStorage.removeItem('keksdose-allowed');
  else localStorage.setItem('keksdose-allowed', allowedModules.toString());
}

export function getBannerDismissed(): boolean {
  return !!localStorage.getItem('keksdose-dismissed');
}

export function setBannerDismissed(dismissed = true): void {
  if (dismissed) localStorage.setItem('keksdose-dismissed', '1');
  else localStorage.removeItem('keksdose-dismissed');
}

export function resetCookiePreferences(): void {
  localStorage.removeItem('keksdose-dismissed');
  localStorage.removeItem('keksdose-allowed');
  window.location.reload();
}

Object.defineProperty(window, 'resetCookiePreferences', {
  value: resetCookiePreferences,
  writable: false,
});
