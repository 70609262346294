import {
  getBannerDismissed,
  setAllowedModules,
  setBannerDismissed,
} from './storage';

function getBanner(): Element {
  const banner = document.getElementById('cookie-banner');
  if (!banner) throw new Error('Missing cookie banner');

  return banner;
}

function getButtons(): { accept: Element[]; reject: Element[] } {
  const accept = Array.prototype.slice.call(
    document.getElementsByClassName('cookie-accept'),
  );
  const reject = Array.prototype.slice.call(
    document.getElementsByClassName('cookie-reject'),
  );

  // TODO: remove id support in major update
  const acceptById = document.getElementById('cookie-accept');
  if (acceptById) accept.push(acceptById);

  const rejectById = document.getElementById('cookie-reject');
  if (rejectById) reject.push(rejectById);

  return { accept, reject };
}

function handleAccept(): void {
  setBannerDismissed();
  setAllowedModules('*');

  window.location.reload();
}

function handleReject(): void {
  setBannerDismissed();
  setAllowedModules([]);

  window.location.reload();
}

function registerEvents({
  accept,
  reject,
}: {
  accept: Element[];
  reject: Element[];
}): void {
  accept.forEach(button => {
    button.addEventListener('click', () => handleAccept());
  });
  reject.forEach(button => {
    button.addEventListener('click', () => handleReject());
  });
}

function removeBanner(banner: Element): void {
  banner.parentNode?.removeChild(banner);
}

export function initializeBanner(): void {
  try {
    const banner = getBanner();
    if (getBannerDismissed()) removeBanner(banner);
  } catch (e) {
    console.warn(e);
  }

  registerEvents(getButtons());
}
